import { fetchTryout, deleteTryout, replicateTryout } from '../../../Features/Learning/Tryout/Utils/TryoutUtils';
import Swal from 'sweetalert2';

/* **************************************************** */
/* CRUD Tryout                                       */
/* **************************************************** */

export const fetchTryouts = (dispatch, filters, refatch) => {
  dispatch(fetchTryout(filters));
};

export const handleDeleteTryout = async (dispatch, id, setRefetch) => {
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(deleteTryout(id));
        setRefetch(prevRefetch => !prevRefetch);
        Swal.fire(
          'Terhapus!',
          'Data telah dihapus.',
          'success'
        )
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }
};

export const handleEditTryout = async (dispatch, item, setField) => {
  const fieldsToUpdate = ['title', 'description', 'time', 'start_at', 'end_at', 'quotas', 'category', 'is_quiz', 'instruction', 'is_report_show'];
  fieldsToUpdate.forEach(field => {
    dispatch(setField({ field, value: item[field] }));
  });
  dispatch(setField({ field: 'ads_videoLink', value: item.ads_video }));
  dispatch(setField({ field: 'tutorial_videoLink', value: item.tutorial_video }));
  dispatch(setField({ field: 'bannerLink', value: item.banner }));
  dispatch(setField({ field: 'isUpdate', value: true }));
  dispatch(setField({ field: 'id', value: item.id }));
};

export const handleGetDetailTryout = async (dispatch, item, setDetail) => {
  // console.log('item:', item);
    dispatch(setDetail(item));
};

export const handleDetach = async (detail, setDetail, item, data, dispatch, detachTryout ,setRefetch) => {
  const newDetail = { ...detail };
  try {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: "Anda tidak dapat mengembalikan data yang telah dihapus!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const success = await dispatch(detachTryout(data))
        if (success.payload === true) {
          setRefetch(prevRefetch => !prevRefetch);
          newDetail.question = newDetail.question.filter((question) => question.id !== item.pivot['question_id']);
          Swal.fire(
            'Terhapus!',
            'Data telah dihapus.',
            'success'
          )
          dispatch(setDetail(newDetail));
        } else if (success.payload === false) {
          Swal.fire(
            'Gagal!',
            'Data gagal dihapus.',
            'error'
          )
        }
        
      }
    })
  } catch (error) {
    console.error("Delete error:", error);
  }

};

export const handleReplicateTryout = async (dispatch, item, setRefetch) => {
  try {
    // Pertanyaan pertama untuk memilih duplikasi dengan atau tanpa soal
    const { value: includeQuestions } = await Swal.fire({
      title: 'Pilih opsi duplikasi',
      input: 'radio',
      inputOptions: {
        1: 'Tanpa soal',
        0: 'Dengan soal'
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Anda harus memilih salah satu opsi!'
        }
      }
    });

    // Jika pengguna telah memilih opsi, lanjutkan ke konfirmasi akhir
    if (includeQuestions !== undefined) {
      const optionText = includeQuestions === '1' ? 'tanpa soal' : 'dengan soal';
      Swal.fire({
        title: 'Apakah anda yakin?',
        html: `Anda akan menduplikasi Tryout <strong>${item.title}</strong> ini ${optionText}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(replicateTryout({ id: item.id, filter: parseInt(includeQuestions) }));
          if (response.payload.status === 201) {
            setRefetch(prevRefetch => !prevRefetch);
            Swal.fire(
              'Berhasil!',
              'Data telah diduplikasi.',
              'success'
            );
          } else {
            Swal.fire(
              'Gagal!',
              'Data gagal diduplikasi.',
              'error'
            );
          }
        }
      });
    }
  } catch (error) {
    console.error("Replicate error:", error);
  }
};

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page > 1) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page - 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
  if (pagination.current_page < pagination.last_page) {
    dispatch(setFilters({
      ...filters,
      page: pagination.current_page + 1
    }));
    setRefetch(prevRefetch => !prevRefetch);
  }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
  console.log(page);
  dispatch(setFilters({
    ...filters,
    page: page
  }));
  setRefetch(prevRefetch => !prevRefetch);
}