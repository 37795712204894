import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row, CardBody } from "reactstrap";
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Image,
	Btn,
	LI,
	P,
	UL,
	Spinner,
} from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	setDetail,
	setFilters,
} from "../../../Features/Learning/Tryout/tryoutSlice";
import { setField } from "../../../Features/Learning/Tryout/addTryoutSlice";
import {
	fetchTryouts,
	handleEditTryout,
	handleDeleteTryout,
	handleGetDetailTryout,
	handleBackPagination,
	handleNextPagination,
	handleClickPagination,
	handleReplicateTryout
} from "./TryoutUtils";
import { fetchCategory } from "../../../Component/Learning/Categories/CategoriesUtils";
import { fetchQuestions } from "../../../Component/Learning/BankQuestion/QuestionsUtils";
import { fetchCategories } from "../../../Features/Learning/Categories/Utils/CategoriesUtils";
import PaginationComponent from "../Common/Pagination";
import Moment from "moment";
import "moment/locale/id";

const TryoutBox = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const filters = useSelector((state) => state.Tryout.filters);
	// const filtersQuestion = useSelector(state => state.Question.filters)
	const filtersCategory = useSelector((state) => state.Categories.filters);
	const cards = useSelector((state) => state.Tryout.data.data);
	const isLoading = useSelector((state) => state.Tryout.isLoading);
	const pagination = useSelector((state) => state.Tryout.data.pagination);
	const [refatch, setRefatch] = useState(false);

	useEffect(() => {
		const newFilterCategory = {
			...filtersCategory,
			"filter[is_parent]": "1",
		};

		const newFilterTryout = {
			...filters,
			"include[1]": "",
		};
		fetchCategory(dispatch, newFilterCategory);
		fetchTryouts(dispatch, newFilterTryout);
		window.scrollTo(0, 0);
		// fetchQuestions(dispatch, filtersQuestion);
	}, [refatch]);

	return (
		<Fragment>
			{isLoading ? (
				<Col lg="12">
					<div className="loader-box">
						<Spinner attrSpinner={{ className: "loader-30" }} />
					</div>
					<H6 attrH6={{ className: "sub-title mb-0 text-center" }}>
						{"Data Loading..."}
					</H6>
				</Col>
			) : (
				cards &&
				cards.map((item) => {
					return (
						<Col sm="6" lg="4" key={`tryout-${item.id}`} className="box-col-6">
							<Card>
								<div className="product-box learning-box">
									<div className="product-img">
										<Image
											attrImage={{
												className: "img-fluid top-radius-blog",
												src: item.banner,
												alt: "",
											}}
										/>
										<div className="product-hover">
											<UL attrUL={{ className: "simple-list" }}>
												<li
													title="Duplicate Tryout"
													onClick={() =>
														handleReplicateTryout(dispatch, item, setRefatch)
													}>
													<i className="icofont icofont-copy-black"></i>
												</li>
												<li
													title="delete"
													onClick={() =>
														handleDeleteTryout(dispatch, item.id, setRefatch)
													}>
													<i className="icofont icofont-trash text-danger"></i>
												</li>
												<Link
													to={`${process.env.PUBLIC_URL}/learning/tryout-add/${item.id}`}
													onClick={() =>
														handleEditTryout(dispatch, item, setField)
													}>
													<li
														title="edit"
														style={{ marginBottom: "10px", marginTop: "10px" }}>
														<i className="icofont icofont-edit"></i>
													</li>
												</Link>
												<Link
													to={`${process.env.PUBLIC_URL}/learning/tryout-detail/${item.id}`}>
													<li title="detail">
														<i className="icofont icofont-info-circle"></i>
													</li>
												</Link>
											</UL>
										</div>
									</div>
									<div className="details-main">
										<Link
											to={`${process.env.PUBLIC_URL}/learning/tryout-detail/${item.id}`}>
											<div className="bottom-details">
												<H6>{item.title}</H6>
											</div>
										</Link>
										<P>
											Start at :{" "}
											{Moment(item.start_at).format("ddd, DD/MM/YYYY")} -{" "}
											{Moment(item.start_at).format("HH:mm:ss")}
										</P>
										<P>
											End at : {Moment(item.end_at).format("ddd, DD/MM/YYYY")} -{" "}
											{Moment(item.end_at).format("HH:mm:ss")}
										</P>
										<div className="blog-bottom-content">
											<UL
												attrUL={{
													className: "simple-list blog-social flex-row",
												}}>
												<LI>
													<b>Kuota : {item.quotas} orang</b>
												</LI>
												<LI>
													<b>Tipe : {item.is_quiz === 1 ? "Kuis" : "Tryout"}</b>
												</LI>
											</UL>
										</div>
										<P>
											created at :{" "}
											{Moment(item.created_at).format("ddd, DD/MM/YYYY")} -{" "}
											{Moment(item.created_at).format("HH:mm:ss")}
										</P>
									</div>
								</div>
							</Card>
						</Col>
					);
				})
			)}
			{!cards ||
				(cards.length === 0 && (
					<Col lg="12">
						<H4 attrH4={{ className: "sub-title mb-5 text-center" }}>
							{!cards ? "Sumber Data Tidak Tersedia" : "Data Tidak Ditemukan"}
						</H4>
					</Col>
				))}
			`
			<CardBody>
				{isLoading === false && cards && cards.length > 0 && pagination && (
					<PaginationComponent
						pagination={pagination}
						handleBackPagination={() =>
							handleBackPagination(
								pagination,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
						handleClickPagination={(page) =>
							handleClickPagination(
								page,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
						handleNextPagination={() =>
							handleNextPagination(
								pagination,
								filters,
								dispatch,
								setFilters,
								setRefatch
							)
						}
					/>
				)}
			</CardBody>
		</Fragment>
	);
};

export default TryoutBox;
